import React from "react"
import Layout from "../components/layout"
import SEO from "../components/layout/seo"

import companyPic from "../img/companyPic.png"
import noLimitIcon0 from "../img/icons/noLimitIcon_0.png"
import noLimitIcon1 from "../img/icons/noLimitIcon_1.png"
import noLimitIcon2 from "../img/icons/noLimitIcon_2.png"
import noLimitIcon3 from "../img/icons/noLimitIcon_3.png"
import noLimitIcon4 from "../img/icons/noLimitIcon_4.png"
import noLimitIcon5 from "../img/icons/noLimitIcon_5.png"
import noLimitIcon6 from "../img/icons/noLimitIcon_6.png"
import noLimitImg from "../img/noLimitImg.jpg"
import etriLogo from "../img/logo/logo_etri.png"
import miniLogo from "../img/logo/logo_mini.png"
import ttaLogo from "../img/logo/logo_tta.png"
import ieftLogo from "../img/logo/logo_ietf.png"
import ituLogo from "../img/logo/logo_itu.png"
import keicLogo from "../img/logo/logo_keic.png"
import koselLogo from "../img/logo/logo_kosel.png"
import isoLogo from "../img/logo/logo_iso.png"
import w3cLogo from "../img/logo/logo_w3c.png"
import ocfLogo from "../img/logo/logo_ocf.png"
import kbizLogo from "../img/logo/logo_kbiz.png"

import believeIcon0 from "../img/icons/believe_01.png"
import believeIcon1 from "../img/icons/believe_02.png"
import believeIcon2 from "../img/icons/believe_03.png"

import certIcon from "../img/cert/icon_cert.png"
import licenIcon from "../img/cert/icon_licen.png"

import ttaCert from "../img/cert/m2mTech_cert_tta.pdf"
import patentCert from "../img/cert/m2mTech_cert_patent.pdf"
import isoCert from "../img/cert/m2mTech_cert_iso.pdf"
import busiCert from "../img/cert/m2mTech_cert_business.pdf"
import ventureCert from "../img/cert/m2mTech_cert_venture.pdf"
import memberCert from "../img/cert/m2mTech_cert_member.jpg"
import softwareCert from "../img/cert/m2mTech_cert_software.pdf"
import rndCert from "../img/cert/m2mTech_cert_rnd.jpg"

//import noLimitIcon0 from "./icons/noLimitIcon_0.png";
//import noLimitIcon from "./icons";

const AboutUs = () => (
  <Layout>
    <SEO title="M2MTech" />
    <div className="aboutUsWrap">
      <div className="sectionWrap overviewWrap">
        <p className="imgWrap">
          <img src={companyPic} alt="img" />
        </p>
        <div className="textWrap">
          <h2 style={{ fontSize: "48px" }}>
            사물지능융합(AIoT) 전문기업, <br />
            (주)엠투엠테크
          </h2>
          <p className="bodyText">
            (주)엠투엠테크는 스마트 M2M / IoT기술과 서비스를 통해 고객에게 기대
            그이상의 가치를 제공하는 스마트 컨버전스 전문기업입니다. 최근의 기술
            트랜드는 세계가 초연결사회(Hyper – Connected Society)로 나아가고
            있다는 것입니다. 네트워크 기반의 초연결 사회는 모든 객체간
            상호소통이 가능하여 공간적 · 시간적 제약이 없이 정보 접근이 가능한
            스마트 사회를 가능하게 합니다. 엠투엠테크는 사람과 사람, 사람과
            사물, 사물과 사물간 연결을 가능하게 하는 제품/ 서비스의 연구와
            개발을 통하여 우리가 함께 살고 있는 사회가 한층 더 안전하고 편리하며
            나아가 행복으로 연결되기를 꿈꾸며 노력하는 기업이 되고자 합니다.
            <br />
            <br />
            당사는 끊임없는 도전과 새로운 기술혁신으로 글로벌 기준에 부합하는
            핵심 제품/서비스 개발과 사람을 중시하는 기업 문화가 고객에게 존경과
            신뢰를 받는 기본이라는 마음을 가지고 있습니다.
            <span>대표이사 김 진 기</span>
          </p>
        </div>
      </div>
      <div className="sectionWrap noLimitWrap">
        <div className="textWrap">
          <h2>There’s no boundary.</h2>
          <p className="bodyText">
            엠투엠테크는 여러 분야의 기술을 융합하여 더 큰 부가가치를 가진
            상품과 서비스를 창조합니다. 인공지능과 사물인터넷, 데이터분석처리
            그리고 클라우드 등 많은 분야에서 프로젝트를 진행하고 있습니다.
          </p>
        </div>
        <ul className="noLimitUl">
          <li>
            <p className="imgWrap">
              <img src={noLimitIcon0} alt="" />
            </p>
            <p>
              인공지능 사물인터넷
              <span className="eng">AI IoT</span>
            </p>
          </li>
          <li>
            <p className="imgWrap">
              <img src={noLimitIcon1} alt="" />
            </p>
            <p>
              엣지
              <span className="eng">Edge computing</span>
            </p>
          </li>
          <li>
            <p className="imgWrap">
              <img src={noLimitIcon2} alt="" />
            </p>
            <p>
              클라우드
              <span className="eng">Cloud</span>
            </p>
          </li>
          <li>
            <p className="imgWrap">
              <img src={noLimitIcon3} alt="" />
            </p>
            <p>
              노후화, 상태 오류체크
              <br />
              모니터링 시스템
              <span className="eng">divice health check, monitoring</span>
            </p>
          </li>
          <li>
            <p className="imgWrap">
              <img src={noLimitIcon4} alt="" />
            </p>
            <p>
              플랫폼
              <span className="eng">platform</span>
            </p>
          </li>
          <li>
            <p className="imgWrap">
              <img src={noLimitIcon5} alt="" />
            </p>
            <p>
              데이터 분석 처리
              <span className="eng">data processing</span>
            </p>
          </li>
          <li>
            <p className="imgWrap">
              <img src={noLimitIcon6} alt="" />
            </p>
            <p>
              종합업무자동화 시스템
              <span className="eng">ERP service</span>
            </p>
          </li>
        </ul>
        <div className="noLimitImg">
          <img src={noLimitImg} alt="" />
        </div>
        <div className="textWrap">
          <h2>Who we work with</h2>
          <p className="bodyText">
            엠투엠테크는 국가 공공기관 국제 표준화기구와 함께 주관으로
            정보통신기술협회 등 산업분야의 기준을 세우고 기술견인을
            하고있습니다. 지속적인 정부부처 및 국제기구와의 협력을 통해 TTF
            표준제정 (진행중), 국가표준, 한국승강기공업협동조합 단체표준
            제정(완료), IYU-T SG 20 제안, ISO TC 178 참여(진행중) 등 국가적
            기술향상에 참여하겠습니다.
          </p>
        </div>
        <ul className="workWUl">
          <li>
            <p className="imgWrap">
              <img src={miniLogo} alt="" />
            </p>
            <p>행정안전부</p>
          </li>
          <li>
            <p className="imgWrap">
              <img src={miniLogo} alt="" />
            </p>
            <p>산업통상자원부</p>
          </li>
          <li>
            <p className="imgWrap">
              <img src={miniLogo} alt="" />
            </p>
            <p>과학기술정보통신부</p>
          </li>
          <li>
            <p className="imgWrap">
              <img src={miniLogo} alt="" />
            </p>
            <p>국가기술표준원</p>
          </li>
          <li>
            <p className="imgWrap">
              <img src={etriLogo} alt="" />
            </p>
            <p>ETRI 한국전자통신연구원</p>
          </li>
          <li>
            <p className="imgWrap">
              <img src={ttaLogo} alt="" />
            </p>
            <p>TTA 한국정보통신기술협회</p>
          </li>
          <li>
            <p className="imgWrap">
              <img src={ieftLogo} alt="" />
            </p>
            <p>IETF 국제인터넷표준화기구</p>
          </li>
          <li>
            <p className="imgWrap">
              <img src={ituLogo} alt="" />
            </p>
            <p>ITU-T 국제전기통신연합</p>
          </li>
          <li>
            <p className="imgWrap">
              <img src={keicLogo} alt="" />
            </p>
            <p>한국승강기공업협동조합</p>
          </li>
          <li>
            <p className="imgWrap">
              <img src={isoLogo} alt="" />
            </p>
            <p>ISO 178, ISO 9001</p>
          </li>
          <li>
            <p className="imgWrap">
              <img src={w3cLogo} alt="" />
            </p>
            <p>W3C</p>
          </li>
          <li>
            <p className="imgWrap">
              <img src={ocfLogo} alt="" />
            </p>
            <p>Open Connectivity Foundation</p>
          </li>
          <li>
            <p className="imgWrap">
              <img src={koselLogo} alt="" />
            </p>
            <p>한국승강기학회</p>
          </li>
          <li>
            <p className="imgWrap">
              <img src={kbizLogo} alt="" />
            </p>
            <p>중소기업중앙회</p>
          </li>
        </ul>
      </div>
      <div className="sectionWrap believeWrap">
        <div className="textWrap">
          <h2>Things we believe in.</h2>
        </div>
        <ul className="believeUl">
          <li>
            <p className="imgWrap">
              <img src={believeIcon0} alt="" />
            </p>
            <p>기업은 사람이다</p>
            <p className="bodyText">
              <strong>엠투엠테크는 사람을 우선합니다.</strong>
              <br />
              소통과 협업의 중심에 사람을 먼저 생각하는 기업문화를 만들어
              가겠습니다.
            </p>
          </li>
          <li>
            <p className="imgWrap">
              <img src={believeIcon1} alt="" />
            </p>
            <p>기업의 투명성, 사회적 책임</p>
            <p className="bodyText">
              <strong>‘재상평여수 인중직사형 (財上平如水 人中直似衡)’</strong>
              <br />
              ‘재물은 평등하기가 물과 같고, 사람은 바르기가 저울과 같다’고
              하였습니다. 기업의 투명성을 확보하고 공익증진을 위해 노력함으로써
              지속 가능한 기업으로 도약할것입니다.
            </p>
          </li>
          <li>
            <p className="imgWrap">
              <img src={believeIcon2} alt="" />
            </p>
            <p>새로운 가치 창출</p>
            <p className="bodyText">
              <strong>새로운 가치를 창출합니다.</strong>
              <br />
              경쟁보다는 새로운 가치의 창출에 집중하고, 행복하게 일하는 공동체를
              지향하는 전문가 그룹으로 성장할 것입니다.
            </p>
          </li>
        </ul>
      </div>
      <div className="sectionWrap certWrap">
        <p className="textWrap">Business Licence &amp; Certificates</p>
        <ul>
          <li>
            <img src={licenIcon} alt="" />
            <a href={busiCert} target="_blank" rel="noreferrer">
              사업자등록증
            </a>
          </li>
          <li>
            <img src={licenIcon} alt="" />
            <a href={softwareCert} target="_blank" rel="noreferrer">
              소프트웨어사업자 신고확인서
            </a>
          </li>
          <li>
            <img src={licenIcon} alt="" />
            <a href={ventureCert} target="_blank" rel="noreferrer">
              벤처기업 확인서
            </a>
          </li>
          <li>
            <img src={certIcon} alt="" />
            <a href={patentCert} target="_blank" rel="noreferrer">
              특허등록증
            </a>
          </li>
          <li>
            <img src={certIcon} alt="" />
            <a href={isoCert} target="_blank" rel="noreferrer">
              경영시스템인증서
            </a>
          </li>
          <li>
            <img src={certIcon} alt="" />
            <a href={rndCert} target="_blank" rel="noreferrer">
              기업부설연구소 인정서
            </a>
          </li>
          <li>
            <img src={certIcon} alt="" />
            <a href={ttaCert} target="_blank" rel="noreferrer">
              TTA 인증서
            </a>
          </li>
          <li>
            <img src={certIcon} alt="" />
            <a href={memberCert} target="_blank" rel="noreferrer">
              한국산업기술진흥협회 회원증
            </a>
          </li>
        </ul>
      </div>
    </div>
  </Layout>
)

export default AboutUs
